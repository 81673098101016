<template>
  <div>
    <portal to="header">
      <terminal-header
          :title="$t('Acceptances')"
          @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema"/>
    </portal>

    <div class="q-pa-sm border-bottom row items-center text-grey-7 text-weight-bold q-mb-xs">
      <h6 class="q-ma-none text-subtitle2 text-weight-bold">
        {{ $t('Receiving') }}
      </h6>

      <q-badge
          color="info"
          class="q-py-xs q-mx-sm"
          :outline="isNewChosen"
          style="cursor:pointer"
          @click="loadNewDocuments"
      >
        <span class="q-mr-sm">
          {{ $t('new') }}
        </span>

        <q-spinner
            v-if="isLoadingNew"
            color="light-blue-9"
            size="1rem"
        />

        <span v-else>
          {{ newItems }}
        </span>
      </q-badge>

      <q-badge
          color="warning"
          class="q-py-xs q-mx-sm"
          :outline="isCheckingChosen"
          style="cursor:pointer"
          @click="loadCheckingDocuments"
      >
        <span class="q-mr-sm">
          {{ $t('checking') }}
        </span>

        <q-spinner
            v-if="isLoadingChecking"
            color="light-blue-9"
            size="1rem"
        />

        <span v-else>
          {{ checkingItems }}
        </span>
      </q-badge>

    </div>

    <div v-if="items.length === 0 && !$service.acceptance.isLoading" class="q-pa-md q-gutter-sm">
      <q-banner inline-actions rounded class="bg-orange text-white">
        {{ $t('There was no purchase orders found') }}

        <!--        <template v-slot:action>-->
        <!--          <q-btn flat label="Turn ON Wifi" />-->
        <!--          <q-btn flat label="Dismiss" />-->
        <!--        </template>-->
      </q-banner>
    </div>

    <q-table
        :style="`height: ${terminalContainerHeight - 50}px;`"
        row-key="id"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="$service.acceptance.isLoading"
        :filter="filter"
        :rows-per-page-options="[]"
        table-header-class="d-none"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
    >
      <template v-slot:loading>
        <q-inner-loading
            showing
            color="primary"
        />
      </template>

      <template v-slot:body="props">
        <q-tr
            :props="props"
            class="clickable"
            @dblclick="onRowDblClick(props.row)"
        >
          <q-td
              key="details"
              :props="props"
              style="max-width: 100vw; padding: 0 6px 6px !important;"
              class="no-border"
          >
            <div class="border border--radius-sm rounded q-py-xs q-px-sm">
              <div class="row items-center q-py-xs q-mb-xs">
                <q-badge
                    :color="stateColors[props.row.state]"
                    :label="$t(props.row.state)"
                    class="q-py-xs q-mr-sm text-capitalize"
                />

                <q-badge
                    :color="typeColors[props.row.type]"
                    :label="$t(typeLabels[props.row.type] || props.row.type) + ' ' + extractItems(props.row.items)"
                    class="q-py-xs"
                />

                <q-space/>

                <div v-if="props.row.extDate">
                  {{ $moment(props.row.extDate.date).format($appOptions.formats.date) }}
                </div>
              </div>

              <div
                  v-if="props.row._embedded && props.row._embedded.warehouse"
                  class="text-h6 text-center text-capitalize text-weight-bold"
                  style="white-space: normal !important;"
              >
                {{ props.row._embedded.warehouse.name }}
              </div>

              <div class="text-caption text-weight-bold text-center">
                {{ $t('ID') + ': ' + props.row.id }}
              </div>

              <div v-if="props.row.extId" class="text-caption text-weight-bold text-center">
                {{ $t('Ext. ID') + ': ' + props.row.extId }}
              </div>

              <div v-if="props.row.comment" class="text-caption text-center"
                   style="white-space: normal !important; max-width: calc(100vw - 20px);">
                {{ props.row.comment }}
              </div>
            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Acceptances',
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '',
          name: 'details',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        complete: 'success',
        checking: 'warning',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark'
      },
      typeLabels: {
        products: 'Products',
        items: 'Items',
        palletized: 'Palletized',
        batch: 'Batch & EXP'
      },
      typeColors: {
        products: 'blue-3',
        items: 'deep-purple-3',
        batch: 'green-2',
        palletized: 'teal-11'
      },
      helper: null,
      items: [],
      newItems: 0,
      checkingItems: 0,
      isNewChosen: false,
      isCheckingChosen: false,
      isLoadingNew: false,
      isLoadingChecking: false
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ]),
    tilesSchema () {
      return [
        {
          id: 1,
          label: this.$t('Create a PO'),
          icon: 'add',
          value: true,
          onChanged: () => {
            this.createAcceptance()
          }
        },
        {
          id: 2,
          label: this.$t('Refresh'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.refresh()
          }
        }
      ]
    }
  },
  mounted () {
    const data = {
      message: 'Please select the Purchase order or create a new one!',
      disabledNotifications: true
    }
    this.addHelper(data)
        .then(helper => {
          this.helper = helper
        })

    this.refresh()
        .then(() => {
          this.isLoadingNew = true
          this.loadDocuments('new')
              .then(({ totalItems }) => {
                this.isLoadingNew = false
                this.newItems = totalItems

                this.isLoadingChecking = true

                this.loadDocuments('checking')
                    .then(({ totalItems }) => {
                      this.isLoadingChecking = false
                      this.checkingItems = totalItems
                      this.isLoadingChecking = false
                      this.allItems = this.newItems + this.checkingItems
                    })
                    .catch(() => {
                      this.isLoadingChecking = false
                    })
              })
              .catch(() => {
                this.isLoadingNew = false
              })
        })
  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }
  },
  methods: {
    ...mapMutations([
      'removeHelper'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    extractItems (items) {
      if (!Array.isArray(items) || items.length <= 0) {
        return ''
      }

      return items.reduce((acc, item) => acc + Number(item.count || 0), 0)
    },
    loadDocuments (value) {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          { type: 'eq', field: 'state', value }
        ]
      }

      return this.$service.acceptance.getAll(query)
    },
    loadNewDocuments () {
      if (!this.isNewChosen) {
        const query = {
          page: 1,
          per_page: 5,
          filter: [
            { type: 'eq', field: 'state', value: 'new' }
          ]
        }

        return this.$service.acceptance.getAll(query)
            .then(({ page, totalItems, items, totalPages }) => {
              this.pagination = {
                ...this.pagination,
                page,
                rowsNumber: totalItems
              }

              this.items = items
              this.isNewChosen = true
              this.isCheckingChosen = false
              return { page, totalItems, items, totalPages }
            })
      }
      return this.onRequest()

    },
    loadCheckingDocuments () {
      if (!this.isCheckingChosen) {
        const query = {
          page: 1,
          per_page: 5,
          filter: [
            { type: 'eq', field: 'state', value: 'checking' }
          ]
        }

        return this.$service.acceptance.getAll(query)
            .then(({ page, totalItems, items, totalPages }) => {
              this.pagination = {
                ...this.pagination,
                page,
                rowsNumber: totalItems
              }

              this.items = items
              this.isCheckingChosen = true
              this.isNewChosen = false
              return { page, totalItems, items, totalPages }
            })
      }
      return this.onRequest()
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      return this.$service.acceptance.getAll(query)
          .then(({ page, totalItems, items, totalPages }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items
            this.isNewChosen = false
            this.isCheckingChosen = false
            return { page, totalItems, items, totalPages }
          })
    },
    handleOpenCamera () {
      this.$refs.barcodeField.openCamera()
    },
    refresh () {
      const pagination = {
        per_page: 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          {
            field: 'state',
            type: 'in',
            values: ['new', 'checking', 'preset']
          }
        ]
      }

      return this.onRequest({ pagination })
    },
    handleBarcode (barcode, acceptanceIds) {
      let pagination
      if (barcode.type === 'P/O') {
        pagination = {
          page: 1,
          search: barcode.value
        }
      } else {
        pagination = {
          page: 1,
          search: barcode.raw
          // filter: [
          //   { type: 'eq', field: 'id', value: barcode.raw }
          // ]
        }
      }

      if (acceptanceIds) {
        pagination.filter = [
          { type: 'in', field: 'id', values: acceptanceIds }
        ]

        pagination.search = null
      }

      this.onRequest({ pagination })
          .then(({ items }) => {
            if (items.length === 0) {
              this.$service.acceptanceItem.getAll({
                search: pagination.search,
                filter: [
                  { type: 'eq', field: 'state', value: 'new' }
                  // { type: 'eq', field: 'sku', value: barcode.raw }
                ]
              })
                  .then(({ page, totalItems, items, totalPages }) => {
                    const acceptanceIds = items.map(x => x._embedded?.document?.id).filter((value, index, self) => {
                      return self.indexOf(value) === index
                    })

                    this.handleBarcode(barcode, acceptanceIds)

                    return { page, totalItems, items, totalPages }
                  })
            } else if (items.length === 1) {
              this.onRowDblClick(items[0])
            }
          })
    },
    createAcceptance () {
      this.$router.push('/terminal/acceptance')
    },
    onRowDblClick (row) {
      this.$router.push('/terminal/acceptance/' + row.id)
    }
  }
}
</script>

<style>
.table--border .q-table tbody .q-tr td {
  border-bottom-width: 2px;
}
</style>
